import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Button from "components/Button";
import Layout from "components/Layout";
import { Grid, Container } from "components/Grid";
import Hero from "components/Hero";

import { Content } from "./styles";

export const LegalPostTemplate = ({ page, markdown }) => {
  return (
    <>
      <Hero content={page.hero} />
      <Grid mb="150px">
        <Container>
          <Button mx={["auto", "0"]} mt="-50px" mb="50px" to="/legal">
            Back to Legal
          </Button>
          <Content dangerouslySetInnerHTML={{ __html: markdown }} />
          <Button mt="50px" mb="50px" to="/legal">
            Back to Legal
          </Button>
        </Container>
      </Grid>
    </>
  );
};

// LegalPostTemplate.propTypes = {
//   title: PropTypes.string
// };

const LegalPost = ({ data }) => {
  const { frontmatter: page, html: markdown } = data.markdownRemark;

  return (
    <Layout content={page}>
      <LegalPostTemplate page={page} markdown={markdown} />
    </Layout>
  );
};

LegalPost.propTypes = {
  data: PropTypes.object.isRequired
};

export default LegalPost;

export const legalPostQuery = graphql`
  query LegalPost($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        page {
          title
          background {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        hero {
          heading
          # date(formatString: "MMMM DD, YYYY")
        }
      }
    }
  }
`;
