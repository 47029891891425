import styled from "styled-components";

export const Content = styled.article`
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
`;
